import React, { Component } from "react";
import ReactGA from "react-ga";
import "./App.css";
import {BrowserRouter as Routers, Route, Switch} from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import {hospitalCode, serverLink} from "./resources/url";
import axios from "axios";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      serverLink: serverLink,
      hospitalCode: hospitalCode,
      logo: '',
      hospitalName: '',
      color: '',
      phoneNumber: '',
      emailAddress: '',
      address: ''
    };

    ReactGA.initialize("BAZE-UNIVERSITY-TEACHING-HOSPITAL-MACHINE");
    ReactGA.pageview(window.location.pathname);
  }

  componentDidMount() {
    this.onLoadHospitalData();
  }

  async onLoadHospitalData () {
    await axios.get(`${serverLink}hospital/${this.state.hospitalCode}`)
        .then(result => {
          this.setState({
            logo: serverLink+'public/uploads/hospital/'+result.data.logo,
            hospitalName: result.data.hospitalName,
            color: result.data.color,
            phoneNumber: result.data.phoneNumber,
            emailAddress: result.data.emailAddress,
            address: result.data.hospitalAddress,
          });
        })
        .catch(error => {
          console.log('Hospital Record not fetch', error)
        })
  }

  render() {
    return (
      <div className="App">
        <Routers>
          <Switch>
            <Route exact path="/">
              <Dashboard name={this.state.hospitalName} phone={this.state.phoneNumber} email={this.state.emailAddress} address={this.state.address} />
            </Route>

          </Switch>
        </Routers>

      </div>
    );
  }
}

export default App;
