import React, {Component} from "react";
import Header from "./Header";

class Dashboard extends Component {

    render() {
        return (
            <div>
                <Header />
            </div>
        );
    }
}
export default Dashboard
