import React, { Component } from "react";
import {Link} from "react-router-dom";
import "bootstrap/dist/js/bootstrap.min";
import {Carousel, Row, Button, Col} from "react-bootstrap";

class Header extends Component {
    render() {
    return (
        <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100" height="900px"
                        src="./assets/img/bg-1.jpg?text=Baze University Hospital First Slide&bg=f5f5f5"
                        alt="First slide"
                    />
                    <Carousel.Caption style={{paddingBottom: "100px"}}>
                        <img
                            className="" width="150px"
                            src="https://smartsourcing-hms.herokuapp.com/public/uploads/hospital/419606d8-761c-40b8-879e-30fc44449ff5-buh.png"
                            alt="Logo"
                        />
                        <h1 style={{ fontSize: '100px' }}>Welcome to <span>Baze University Hospital</span></h1>
                        <Row className="mx-0">
                            <Button as={Col} style={{ fontSize: '50px' }} variant="secondary">
                                <a style={{ textDecoration:"none", "color":"white" }} href="https://bazeuniversityhospital.com/patient/register">
                                    <i className="bi bi-person"></i>New Patient
                                </a>
                            </Button>
                            <p></p>
                            <Button as={Col} style={{ fontSize: '50px' }} variant="secondary">
                                <a style={{ textDecoration:"none", "color":"white" }} href="https://bazeuniversityhospital.com/patient/login">
                                    <i className="bi bi-person"></i>Registered Patient
                                </a>
                            </Button>
                            <p></p>
                            <Button as={Col} style={{ fontSize: '50px' }} variant="secondary">
                                <a style={{ textDecoration:"none", "color":"white" }} href="https://staff.bazeuniversityhospital.com/">
                                    <i className="bi bi-person"></i>Staff
                                </a>
                            </Button>
                        </Row>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"  height="900px"
                        src="./assets/img/bg-2.jpg?text=Baze University Hospital Second slide&bg=eee"
                        alt="Second slide"
                    />
                    <Carousel.Caption style={{paddingBottom: "100px"}}>
                        <img
                            className="" width="150px"
                            src="https://smartsourcing-hms.herokuapp.com/public/uploads/hospital/419606d8-761c-40b8-879e-30fc44449ff5-buh.png"
                            alt="Logo"
                        />
                        <h1 style={{ fontSize: '100px' }}>Welcome to <span>Baze University Hospital</span></h1>
                        <Row className="mx-0">
                            <Button as={Col} style={{ fontSize: '50px' }} variant="secondary">
                                <a style={{ textDecoration:"none", "color":"white" }} href="https://bazeuniversityhospital.com/patient/register">
                                    <i className="bi bi-person"></i>New Patient
                                </a>
                            </Button>
                            <p></p>
                            <Button as={Col} style={{ fontSize: '50px' }} variant="secondary">
                                <a style={{ textDecoration:"none", "color":"white" }} href="https://bazeuniversityhospital.com/patient/login">
                                    <i className="bi bi-person"></i>Registered Patient
                                </a>
                            </Button>
                            <p></p>
                            <Button as={Col} style={{ fontSize: '50px' }} variant="secondary">
                                <a style={{ textDecoration:"none", "color":"white" }} href="https://staff.bazeuniversityhospital.com/">
                                    <i className="bi bi-person"></i>Staff
                                </a>
                            </Button>
                        </Row>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

    );
  }
}

export default Header;
